/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import Img from "gatsby-image"

import { Large } from "components/type"

const BoardMember = ({ image, name, position }) => (
  <Box
    sx={{ mt: position ? 4 : 5, mb: position ? 4 : 5, position: "relative" }}
  >
    <Box
      sx={{
        left: 3,
        position: "absolute",
        top: position ? 3 : -3,
        bottom: position ? "auto" : -3,
      }}
    >
      {image ? (
        <Img
          fixed={image}
          alt=""
          sx={{
            borderRadius: "default",
            height: "auto",
            width: "calc(150px + 2rem)",
          }}
        />
      ) : null}
    </Box>
    <Styled.h2
      as="p"
      sx={{
        bg: position ? "primary" : "secondary",
        borderRadius: "default",
        color: position ? "background" : "dark",
        fontSize: position ? [3, 4, 6] : [2, 3, 4],
        my: 0,
        p: 3,
        pt: position ? 4 : 3,
        pl: "calc(150px + 2rem)",
      }}
    >
      {name}
    </Styled.h2>
    {position ? (
      <Large
        sx={{
          color: "dark",
          fontWeight: "heading",
          pl: "calc(150px + 2rem)",
          pb: 4,
          pt: 2,
        }}
      >
        {position}
      </Large>
    ) : null}
  </Box>
)

export default BoardMember
