/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout, { Container, Row, Col } from "components/layout"
import Hero from "components/hero"
import BoardMember from "components/board-member"

const styles = {
  col: {
    width: ["100%", "100%", "50%"],
  },
  colSm: {
    width: ["100%", "50%", "33.333%"],
  },
}

const BoardPage = ({ data }) => {
  const pageTitle = "Board of Directors"
  return (
    <Layout title={pageTitle}>
      <Hero image={data.hero.childImageSharp.fluid} title={pageTitle} />
      <Container>
        <Row sx={{ flexWrap: "wrap" }}>
          <Col sx={styles.col}>
            <BoardMember
              image={data.cathy.childImageSharp.fixed}
              name="Cathy Hennessey"
              position="Co-Chair"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.samira.childImageSharp.fixed}
              name="Samira Viswanathan"
              position="Co-Chair"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.marguerite.childImageSharp.fixed}
              name="Marguerite Rea"
              position="Co-Vice-Chair"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.sonia.childImageSharp.fixed}
              name="Sonia Yung"
              position="Co-Vice-Chair"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.robert.childImageSharp.fixed}
              name="Robert Gore"
              position="Co-Treasurer"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.frank.childImageSharp.fixed}
              name="Frank Pagliarello"
              position="Co-Treasurer"
            />
          </Col>
          <Col sx={styles.col}>
            <BoardMember
              image={data.jennifer.childImageSharp.fixed}
              name="Jennifer Rae"
              position="Secretary"
            />
          </Col>
        </Row>
        <Row sx={{ flexWrap: "wrap" }}>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.alphonse.childImageSharp.fixed}
              name="Alphonse Barikage"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.varinder.childImageSharp.fixed}
              name="Vinny Bhathal"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.katherine.childImageSharp.fixed}
              name="Katherine Chau"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.mary.childImageSharp.fixed}
              name="Mary Christie"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.sarah.childImageSharp.fixed}
              name="Sarah Doyle"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.philip.childImageSharp.fixed}
              name="Philip Fernandez"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.hartviksen.childImageSharp.fixed}
              name="Jennifer Hartviksen"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.tim.childImageSharp.fixed}
              name="Tim Hughes"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.sarahHurrie.childImageSharp.fixed}
              name="Sarah Hurrle"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.josh.childImageSharp.fixed}
              name="Josh Kleiman"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.craig.childImageSharp.fixed}
              name="Craig Knowles"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.amana.childImageSharp.fixed}
              name="Amana Manori"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.shannon.childImageSharp.fixed}
              name="Shannon McCauley"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.rebecca.childImageSharp.fixed}
              name="Rebecca Stulberg"
            />
          </Col>
          <Col sx={styles.colSm}>
            <BoardMember
              image={data.sapna.childImageSharp.fixed}
              name="Sapna Thakker"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BoardPage

export const pageQuery = graphql`
  query BoardPageQuery {
    hero: file(relativePath: { eq: "board-of-directors.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alphonse: file(relativePath: { eq: "board-alphonse.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amana: file(relativePath: { eq: "board-amana.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cathy: file(relativePath: { eq: "board-cathy.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    craig: file(relativePath: { eq: "board-craig.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    frank: file(relativePath: { eq: "board-frank.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hartviksen: file(relativePath: { eq: "board-hartviksen.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tim: file(relativePath: { eq: "board-tim.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jennifer: file(relativePath: { eq: "board-jennifer.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    josh: file(relativePath: { eq: "board-josh.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    katherine: file(relativePath: { eq: "board-katherine.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    marguerite: file(relativePath: { eq: "board-marguerite.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mary: file(relativePath: { eq: "board-mary.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    philip: file(relativePath: { eq: "board-philip.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rebecca: file(relativePath: { eq: "board-rebecca.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    robert: file(relativePath: { eq: "board-robert.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    samira: file(relativePath: { eq: "board-samira.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sapna: file(relativePath: { eq: "board-sapna.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sarah: file(relativePath: { eq: "board-sarah.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sarahHurrie: file(relativePath: { eq: "board-sarah-hurrie.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    shannon: file(relativePath: { eq: "board-shannon.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sonia: file(relativePath: { eq: "board-sonia.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    varinder: file(relativePath: { eq: "board-varinder.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    yogen: file(relativePath: { eq: "board-yogen.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
